function handleNameForEligibility() {
    const eligibilityBlock = $('#eligibility-block');
    const nameContainer = $('.eligibility-company-name');
    $('#vendor_name').blur(function () {
        const name = $(this).val();
        eligibilityBlock.toggle(!!name);
        name && nameContainer.text(`${name.trim()}`)
    }).trigger('blur');
}


exports.handleNameForEligibility = handleNameForEligibility;