function fixCustomFileInput(input) {
    const $input = $(input);
    
    $input.each(function () {
        $(this).change(function () {
            const defaultText = $(this).text() || 'Выберите файл';
            const files = $(this)[0].files;
            const $label = $(this).siblings('.custom-file-label');
            
            if (files && files.length === 1) {
                let name = files[0].name;
                const limit = 30;
                name = name.length > limit ? name.substr(0, limit) + '...' : name;
                $label.text(name);
            } else if (files && files.length > 1) {
                $label.text(`Выбрано файлов: ${files.length}`)
            } else {
                $label.text(defaultText);
            }
        })
    })
}

function toggleInput(checkbox, input, radioId, selectId) {
    const $checkbox = $(checkbox);
    const $input = $(input);
    
    const showBlock = () => {
        $input.show()
    };
    const hideBlock = () => {
        $input.hide().find('input').val('').trigger('change');
    };
    
    $input.hide();
    
    $checkbox.change(function () {
        const checked = $(this).prop('checked');
        // for radio buttons
        if (radioId) {
            $(this).attr('id') === radioId ? showBlock() : hideBlock();
        } else if (selectId) {
            $(this).val().includes(selectId) ? showBlock() : hideBlock();
        } else {
            // for checkboxes
            checked ? showBlock() : hideBlock();
        }
    })
}


exports.fixCustomFileInput = fixCustomFileInput;
exports.toggleInput = toggleInput;