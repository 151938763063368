function triggerAlerts() {
    $('.trigger-alert').change(function () {
        const checked = $(this).prop('checked');
        const className = $(this).attr('data-alert');
        const alert = $(this).closest('.alert');
        alert.length && className && alert.toggleClass(className, !!checked);
    })
}


exports.triggerAlerts = triggerAlerts;