function toggleElementByStateWithBinding(bindToElement, blockToToggle) {
    const block = $(blockToToggle);
    
    block.hide();
    $(bindToElement).blur(function () {
        const val = $(this).val();
        block.toggle(!!val);
    }).trigger('blur');
}

function toggleAllCategories(btn, elements) {
    let state = false;
    $(btn).click(function () {
        $(elements).toggleClass('show', state);
        state = !state;
    })
}

function makeNavLinksActive() {
    const path = window.location.pathname;
    $('#navbarNav').find('a').each(function() {
        if ($(this).attr('href') === path) {
            $(this).closest('.nav-item').addClass('active');
        }
    })
}

function toggleFormValidation(form, validationBtn, noValidationBtn) {
    validationBtn = $(validationBtn);
    noValidationBtn = $(noValidationBtn);
    form = $(form);

    const validate = (state) => () => state ? form.removeAttr('novalidate') : form.attr('novalidate', state);

    validationBtn.click(validate(true));
    noValidationBtn.click(validate(false));
}

function toggleContracts(container, addBtn, deleteBtn) {
    let counter = 6;
    const idsMapper = {
        6: 'sixth',
        7: 'seventh',
        8: 'eighth',
        9: 'ninth',
        10: 'tenth'
    };

    container = $(container);
    addBtn = $(addBtn);
    deleteBtn = $(deleteBtn);

    const checkAdditionalTrs = () => {
        const contractsLen = $('.toggle-contracts-th').length;

        deleteBtn.attr('disabled', !contractsLen);
        addBtn.attr('disabled', contractsLen === 5);
    };

    addBtn
        .click(() => {
            const html = generateHtml(counter);
            container.append(html);
            checkAdditionalTrs();
            counter++;
    });

    deleteBtn
        .click(() => {
            container.find('.toggle-contracts-th').last().remove();
            checkAdditionalTrs();
            counter--;
    });

    const generateHtml = counter => `
        <tr class="toggle-contracts-th">
          <td>
            <input class="form-control" type="text" name="vendor[${idsMapper[counter]}_contract_date]" id="vendor_${idsMapper[counter]}_contract_date">
          </td>
          <td>
            <input class="form-control" type="text" name="vendor[${idsMapper[counter]}_contract_amount]" id="vendor_${idsMapper[counter]}_contract_amount">
          </td>
          <td>
            <input class="form-control" type="text" name="vendor[${idsMapper[counter]}_contract_subject]" id="vendor_${idsMapper[counter]}_contract_subject">
          </td>
          <td>
            <input class="form-control" type="text" name="vendor[${idsMapper[counter]}_contract_customer]" id="vendor_${idsMapper[counter]}_contract_customer">
          </td>
          <td>
            <input class="form-control" type="text" name="vendor[${idsMapper[counter]}_contract_customer_name]" id="vendor_${idsMapper[counter]}_contract_customer_name">
          </td>
          <td>
            <input class="form-control" placeholder="Телефон/Эл.почта" type="text" name="vendor[${idsMapper[counter]}_contract_contact]" id="vendor_${idsMapper[counter]}_contract_contact">
          </td>
        </tr>
    `
}

exports.toggleElementByStateWithBinding = toggleElementByStateWithBinding;
exports.makeNavLinksActive = makeNavLinksActive;
exports.toggleFormValidation = toggleFormValidation;
exports.toggleContracts = toggleContracts;
exports.toggleAllCategories = toggleAllCategories;