const {fixCustomFileInput, toggleInput} = require('./components/checkbox-input-logic');
const {handleNameForEligibility} = require('./components/elibility');
const {triggerAlerts} = require('./components/admin');
const {toggleElementByStateWithBinding, toggleFormValidation, toggleContracts, toggleAllCategories} = require('./components/elementsTogglers');
const {select2WithSearch} = require('./components/select2');

$(document).ready(function () {
    fixCustomFileInput('.custom-file-input');
    toggleInput('#legal-registration', '.legal-registration__file-wrapper');
    toggleInput('#change-name', '.change-name__file-wrapper');
    toggleInput('#activity-kind-another', '.activity-kind-another__detail-wrapper');
    toggleInput('[name="vendor[eligibility]"]', '.eligibility_2_detail_wrapper', 'eligibility_2');
    toggleInput('[name="vendor[has_constituent_company]"]', '.legal-rights-1__detail', 'legal-rights-1');
    toggleInput('[name="vendor[name_changed]"]', '.change-name__file-wrapper', 'change-name');

    toggleElementByStateWithBinding('#vendor_email', '#save-draft');

    handleNameForEligibility();
    triggerAlerts();

    select2WithSearch('#services');
    select2WithSearch('#products');

    toggleInput('#services', '.services__detail', null, '64');

    toggleFormValidation('#new_vendor', '#save', '#save-draft');

    toggleContracts('#toggle-contracts tbody', '#toggle-contracts-add', '#toggle-contracts-delete');

    toggleAllCategories('.collapse-expand-all-btn', '.multi-collapse');

});

